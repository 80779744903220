<template>
	<div class="bg">
		<div class="form-frame">
			<div class="title">设备模型管理系统</div>
			<el-form @keyup.enter.native="Login" :model="loginForm" ref="loginForm" :rules="loginFormRules" label-position="left" label-width="80px">
				<el-form-item prop="username" label="账号">
					<el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item prop="password" label="密码">
					<el-input show-password v-model="loginForm.password" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item style="display: flex; justify-content: flex-end;">
					<el-button @click="Login">登入</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import UserService from '../../service/user.service';
import md5 from 'md5'

export default {
	name: "App",
	data() {
		return {
			loginForm: {
				username: '',
				password: ''
			},
			loginFormRules: {
				username: [{
					required: true,
					message: '请输入用户名',
					trigger: 'blur'
				}],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}]
			}
		}
	},
	beforeMount() {
		this.CheckOn();
	},
	methods: {
		Login() {
			this.$refs['loginForm'].validate((valid)=>{
				if (!valid) {
					return false;
				}
			});

			UserService.Login({
				username: this.loginForm.username,
				password: md5(this.loginForm.password)
			}).then(res=>{
				if (res.code !== 1000) {
					this.$message.error('登录失败，code：' + res.code + ' msg：' + res.msg);
					return;
				}

				this.$message.success('登录成功!');
				window.location.href = "manager.html";
			})
		},
		CheckOn() {
			UserService.CheckOn().then(res=>{
				if (res.code === 1000) {
					window.location.href = "manager.html";
				}
			}).catch((err)=>{
				this.$message.error('网络异常，错误信息:' + err.message);
			})
		}
	}
}
</script>

<style>
body {
	margin: 0
}

.bg {
	width: 100%;
	height: 100vh;
	display: flex;
	background-image: url("../../assets/bg.jpg");
	background-size: cover;
}

.form-frame {
	background-color: rgba(108, 55, 8, 0.21);
	border-radius: 10px;
	margin:auto;
	margin-left: 15%;
	margin-top: 10%;
	padding: 20px 40px;
}

.title {
	font-size: 18px;
	margin-bottom: 20px;
	font-weight: 600;
}

</style>
