import axios from 'axios';

axios.defaults.timeout = 10000;

axios.interceptors.response.use(response=>{
   if (response.status === 200) {
       return Promise.resolve(response.data);
   }
   return Promise.resolve({
       code: -1,
       msg: 'error'
   });
}, (error) => {
    if (error.response.status === 403) {
        return Promise.resolve(error.response.data);
    }
    return Promise.reject(error)
});

export default axios;
