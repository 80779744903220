import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

let projectRouterConfig = [];

export default new VueRouter({
    routes: projectRouterConfig
})
