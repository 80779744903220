import axios from "./config/axios.default.config";
import defaultConfig from "../../config/config.default.js";

class UserService {
    async Login(userForm) {
        return axios.post(`${defaultConfig.baseUrl}/api/v1/user/login`, userForm);
    }

    async Logout() {
        return axios.get(`${defaultConfig.baseUrl}/api/v1/user/logout`);
    }

    async CheckOn() {
        return axios.get(`${defaultConfig.baseUrl}/api/v1/user/checkon`)
    }
}

export default new UserService;
